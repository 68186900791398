/* mobile screen */
.about {
    width: 100%;
    padding-left: 5%;
    clear: both;
    background-color: #ebedf0;
    overflow: hidden;
    background-size: cover;
}

.about h1 {
    z-index: 0;
    margin: 3% auto;
    color: #555;
    z-index: 0;
    font-size: 18px;
}

.about h2 {
    width: 80%;
    color: #555;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.about h1 a {
    padding-top: 20px;
    color: orange;
    font-weight: 400;
}

.about h1 a:hover{
    padding-top: 20px;
    color: red;
}
.aboutimagetext{
    color: white;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    top: 15%;
    font-size: 80px;
    text-shadow: black 5px 5px 5px;

}


/* desktop screen */
@media only screen and (min-width: 800px) {
    .about h1 {
        width: 80%;
        z-index: 0;
        margin: 3% auto;
        color: #555;
        z-index: 0;
        font-size: 30px;
        
    }

    .about h2 {
        width: 80%;
        color: #555;
        margin: 2% auto;
        font-size: 22px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
    }
    .aboutimagetext{
        color: white;
        position: absolute;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        bottom: 60%;
        top: 0%;
        font-size: 250px;
        text-shadow: black 5px 5px 5px;

    }
}