/* mobile screen */
.blog {
    width: 100%;
    padding-left: 5%;
    clear: both;
    background-color: #ebedf0;
    overflow: hidden;
    background-size: cover;
}

.blog h1 {
    z-index: 0;
    margin: 3% auto;
    color: #555;
    z-index: 0;
    font-size: 18px;
}

.blog h2 {
    width: 80%;
    color: #555;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.blog h1 a {
    padding-top: 20px;
    color: orange;
    font-weight: 400;
}

.blog h1 a:hover{
    padding-top: 20px;
    color: red;
}
.blogimagetext{
    color: white;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    top: 15%;
    font-size: 80px;
    text-shadow: black 5px 5px 5px;

}


/* desktop screen */
@media only screen and (min-width: 800px) {
    .blog h1 {
        width: 80%;
        z-index: 0;
        margin: 3% auto;
        color: #555;
        z-index: 0;
        font-size: 30px;
        text-align: center;
        
    }

    .blog h2 {
        width: 65%;
        color: #555;
        margin: auto;
        font-size: 22px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        text-align: center;
    }
    .blog p {
        width: 80%;
        color: #555;
        margin: 2% auto;
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        text-align: center;
    }    
    .blog h4 {
        width: 60%;
        color: #000000;
        margin: 2% auto;
        font-size: 22px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        text-align: center;
        font-weight: 500;
    }

    .blog hr {
        width: 80%;
        color: #555;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
    }    

    .blogimagetext {
        color: white;
        position: absolute;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        bottom: 60%;
        top: 0%;
        font-size: 250px;
        text-shadow: black 5px 5px 5px;
    }
}
img {
    display: block;
    height: 50%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;

}