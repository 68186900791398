@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.bodyContainer {
  overflow: hidden;
  bottom: 0;
  background: rgba(184, 42, 42, 0);
  color: #f3ebeb;
  width: 100%;
}

.container {
  overflow: hidden;
  position: relative;
  margin: auto;
  height: 100%;
}

/*   header  */
.header {
  margin-top: 0px;
  background-color: #ebedf0;
  opacity: 1;
  position: sticky;
  display: flex;
  width: 100%;
  border-bottom: #f3c009fc outset 10px;
}

.img {
  border: #e79216;
  border-radius: 5pc;
  margin-left: 20px;
  padding-top: 5px;
  padding-left: 5px;
  width: 150px;
  height: 150px;
}

.buttonGroup {
  display: grid;
  grid-template-columns: auto auto;
  margin-left: -10px;
}

/* body */
.bgImg {
  height: 800px;
  background-size: 100%;
}

.button {
  color: #2d2928;
  font-size: 16px;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  background: none;
  height: 100%;
  align-items: center;

}

.buttonPhone {
  color: #e79216;
  font-size: 18px;
  width: 100%;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  background: none;
  height: 100%;
  align-items: center;
  text-align: center;
}

.button:hover {
  color: #e79216;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
}

/* Footer */
.footer {
  height: 5vh;
  background-color: #ebedf0;
}

.footer>p {
  padding-top: 10px;
  margin-top: 0px;
  border-top: solid #555 1px;
  font-size: 14px;
  text-align: center;
}

.noPage {
  color:#2d2928;
  font-size: 18px;
  padding: 1rem;
  background-color: #e79216;
  height: 100%;
}

/* desktop screen */
@media only screen and (min-width: 800px) {

  /*   header  */
  .header {
    margin-top: 0px;
    background-color: #ebedf0;
    opacity: 1;
    position: sticky;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    border-bottom: #f3c009fc outset 10px;
  }

  .buttonGroup {
    grid-column: 2;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
  }


  .button {
    color: #2d2928;
    font-size: 16px;
    border: none;
    text-transform: uppercase;
    margin: 20px;
    cursor: pointer;
    background: none;
  }

  .buttonPhone {
    color: #e79216;
    font-size: 24px;
    border: none;
    text-transform: uppercase;
    margin: 20px;
    cursor: pointer;
    background: none;
  }

  /* body */
  .bgImg {
    height: 800px;
    background-size: cover;
  }

}