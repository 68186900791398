
.bookingDetail {
    display: grid;
    height: 100%;
    background-color: #ebedf0;
    overflow: hidden;
    background-size: cover;
    gap:5px;
    grid-template-areas:
        'header header  header'
        'question question  phone '
        'question question  phone '
        'question question  email';
}

.bookingDetail h2 {
    margin: 18px;
    grid-area: header;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 40px;
}

.bookingDetail .questionList {
    grid-area: question;
    text-align: left;
    margin-left: 10%;
    font-size: 16px;
}

li {
    padding: 8px;
    margin-right: 10px;
}

.bookingDetail .contactPhone {
    grid-area: phone;
    display: flex;
    color: #e79216;
}

.bookingDetail .contactEmail {
    grid-area: email;
    display: flex;
    padding-right:10px;
    color: #e79216;
}

h4 {
    margin-top: -1px;
}

.bookingDetail a:link {
    color: #e79216;
}

.bookingDetail a:hover {
    color: #e79216;
}

.bookingDetail a:active {
    color: #e79216;
    font-weight: bold;
}
.bookingimagetext{
    color: white;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    top: 20%;
    font-size: 80px;
    text-shadow: black 5px 5px 5px;

}

@media only screen and (min-width: 800px) {
    .bookingDetail {
        display: grid;
        height: auto;
        background-color: #ebedf0;
        overflow: hidden;
        background-size: cover;
        grid-template-areas:
            'header header header'
            'menu  menu phone'
            'menu  menu email'
    }

    .bookingDetail h2 {
        grid-area: header;
        text-align: center;
        font-size: 30px;
    }

    .bookingDetail .questionList {
        width: 80%;
        grid-area: menu;
        text-align: left;
        margin-left: 10%;
        font-size: 24px;
    }

    .bookingDetail .contactPhone {
        padding-top: 30px;
        grid-area: phone;
        display: grid;
        grid-template-columns: auto auto;
        color: #e79216;
        font-size: 22px;
        font-weight: 200;
    }

    .bookingDetail .contactEmail {
        padding-left: 50px;
        grid-area: email;
        display: grid;
        grid-template-columns: auto auto;
        color: #e79216;
        font-size: 22px;
        font-weight: 200;
    }
    .bookingimagetext{
        color: white;
        position: absolute;
        font-family: 'Archivo Black', sans-serif;
        text-align: center;
        bottom: 60%;
        top: 0%;
        font-size: 250px;
        text-shadow: black 5px 5px 5px;

    }
}