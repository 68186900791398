/* effects everything in intro page */
.intro {
    text-align: left;
    background-color: #e79216;
    background: rgba(0, 0, 0, 0.0);
    padding-top: 10px;
    padding-left: 3%;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-shadow: black 2px 2px 5px;
}

/* mobile paragraphing */
.intro p {
    margin-top: 50px;
    text-align: left;
    padding: 5px;
    color: white;
    font-size: 18px;
    font-weight: 400;
    text-shadow: black 2px 2px 5px;
}

.intro p a {
    padding-top: 20px;
    color: #e79216;
    font-size: 20px;
    font-weight: bold;
    font-size: 20px;
}

 .intro p a {
     text-align: right;
     color: #e79216;
     font-size: 30px;
     font-weight: bold;
     padding-bottom: 40px;

 }
 .intro p a:hover {
    text-align: right;
    color: red;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 40px;

}

/* desktop screen */
@media only screen and (min-width: 800px) {
    .intro {
            text-align: center;
            background-color: #ebedf0;
            background: rgba(0, 0, 0, 0.0);
            text-align: left;
            padding-top: 10px;
            padding-left: 3%;
            font-size: 22px;
        }
    
        .intro p {
            text-align: left;
            padding-top: 15px;
            color: #efefef;
            font-size: 22px;
        }

        .intro p a {
            color: #e79216;
            font-size: 30px;
            font-weight: bold;
            padding-bottom: 40px;
        }


}